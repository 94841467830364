.skills-section {
    display: flex;
    flex-direction: column;
    color: var(--black);
    width: 100%;
    padding: 4rem 0;
    justify-content: center;
    align-items: center;
}
.section-title {
    display: flex;
    justify-content: center;
    width: 100%;
}

.skills-section h2 {
    color: var(--blue);
    margin-bottom: 1rem;
}

.skillsEl-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
}

@media only screen and (max-width: 500px){
    .skills-section {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    .skillsEl-wrapper {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
    }
}

