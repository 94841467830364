.cod-project-container {
    min-width: 120px;
    max-width: 700px;
    margin: 0 2rem 2rem 0;
}

.cod-project-container h3 {
    padding-bottom: 0.8rem;
}

.img-wrapper__ {
    min-width: 200px;
    height: 200px;
    background-color: var(--grey);
}

.img-wrapper__ img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.top-wrapper {
    display: flex;
    border: 1px solid white;
    overflow: hidden;
}

.right-wrapper {
   padding: 1rem 1rem;
}

.proj-btn {
    width: 150px;
    line-height: 34px;
    margin-right: 12px;
}

.cod-proj-desc {
    height: 70px;
    overflow: hidden;
}

.techs {
    margin: 0.8rem 0;
}

@media only screen and (max-width: 900px) {

    .cod-project-container {
        margin-left: 1.4rem;
    }

    .proj-btn {
        width: 120px;
        line-height: 34px;
        margin-right: 10px;
    }
    
    .right-wrapper p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 600px) {
    .img-wrapper__ {
        display: none;
    }
}

