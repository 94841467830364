.about-container {
    display: flex;
    max-width: 600px;
    margin-bottom: 2rem;
    margin-top: 1rem;
    border: 2px solid var(--blue);
    border-radius: 10px;
    padding: 2rem;
}

.abt-header {
    color: var(--blue);
    text-align: center;
}
.about-container p {
    color: var(--blue);
    line-height: 30px;
    text-align: center;
}

@media only screen and (max-width: 700px) {

    .abt-header {
        margin-top: 2rem;
    }

    .about-container {
        margin: 2rem 1.2rem;
    }
}