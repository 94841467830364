.coding-projects-section {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: fit-content;
    background-color: var(--blue);
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 11rem;
}

.cod-title {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;
}

.wave-2{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.wave-2 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 113px;
}

.wave-2 .shape-fill {
    fill: var(--white);
}


.cod-projects-container {
    padding: 2rem 0;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

/* 500 and down */
@media only screen and (max-width: 450px) {
    .wave-2, .wave-3 {
        display: none;
    }

    .coding-projects-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 0;
        padding-bottom: 2rem;
    }

        
    .cod-projects-container {
        display: flex;
        margin: 0;
        padding: 0;
        width: 100%;
        overflow: hidden;
        flex-wrap: wrap;
    }
}