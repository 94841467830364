.hero-container {
    background-color: var(--blue);
    min-height: 820px;
    position: relative;
}

.top-nav ul {
    display: flex;
    list-style: none;
}

.bar {
    margin: 1rem 2.8rem 0rem 2.8rem;
    width: 2px;
    height: 30%;
    background-color: var(--white);
}

.icons-container {
    margin: 1.6rem 2rem;
}

.icon {
    display: block;
    color: var(--white);
    font-size: 28px;
    margin: 10px 0;
}

.icon:hover {
    cursor: pointer;
    color: var(--red);
    transition: color 0.2s;
}

/* CENTER INFO IN THE HERO */
.main-info {
    margin-right: 1rem;
}

.main-info span {
    display: block;
    color: var(--grey);
    font-size: 18px;
}

.main-info h3 {
    color: var(--grey);
}

/* Main Info Buttons */
.btn-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
}

.main-info button {
    min-width: 180px;
    padding: 10px 0;
    font-size: 16px;
    color: var(--white);
}

.main-info button:nth-child(1){
    margin-right: 10px;
}

.hero-img-wrapper {
    min-width: 240px;
    width: 240px;
    height: 250px;
    background-color: var(--white);
    border-radius: 10px;
    position: relative;
}
.hero-img-wrapper img {
    object-fit: cover;
    width: 100%;
    transform: translate(0px,-7px);
    z-index: 2;
}

.img-outline {
    position: absolute;
    width: 240px;
    height: 250px;
    background: none;
    border: 1px solid white;
    top: 0;
    right: 0;
    border-radius: 10px;
    transform: translate(20px,20px);
    z-index: -1;
}

/* WAVE BACKGROUND */
.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(140% + 1.3px);
    height: 203px;
}

.wave .shape-fill {
    fill: var(--white);
}

.nav-link:hover {
    color: var(--red);
    transition: color 0.2s;
}

@media only screen and (max-width: 499px) {

    .main-info button:nth-child(1){
        margin-right: 0px;
    }

    .hero-container {
        min-height: 580px;
    }

    .hero-center {
        width: 300px;
        text-align: center;
    }

    .hero-center h1 {
        font-size: 38px;
    }
        
    .hero-center span {
        display: none;
    }

    .main-info {
        margin-right: 0;
    }

    .main-info h3 {
        font-size: 16px;
    }

    .hero-left {
        display: none;
    }

    .hero-grid{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .wave {
        display: none;
    }

    .hero-img-wrapper {
        min-width: 20px;
        margin: 0 auto;
        width: 180px;
        height: 160px;
        overflow: hidden;
        margin-top: 0.7rem;
    }

    .img-outline {
    display: none;
    }

    .top-nav {
        width: 100%;
        padding: 1rem 0;
    }

    .top-nav ul {
        display: block;
        text-align: center;
        border-bottom: 1.5px solid black;
    }

    .top-nav ul li {
        padding-bottom: 0.8em;
    }

    .btn-wrapper {
        display: block;
    }

    .btn-wrapper button {
        margin-bottom: 0.7rem;
    }

}

/* 500 and up */
@media only screen and (min-width: 500px) {

    .hero-grid{
        display: flex;
        flex-direction: column;
        grid-template-columns: 24% 50%;
        grid-template-rows: 92px 500px;
    }

    .top-nav {
       display: flex;
       justify-content: center;
       padding-top: 2rem;
       align-items: center;
       width: 100%;
    }

    .top-nav ul li{
        padding-right: 1.4rem;
        text-align: center;
     }

    .hero-center {
        display: block;
        margin: 4rem auto;
    }

    .hero-img-wrapper {
        max-width: 240px;
        margin: 2rem auto;
    }

    .hero-left {
        display: none;
    }

    .main-info button {
        min-width: 210px;
        padding: 10px 0;
    }
}

/* 900 and up */ 
@media only screen and (min-width: 900px) { 
   
    .hero-grid{
        display: grid;
        grid-template-columns: 20% 70%;
        grid-template-rows: 92px 500px;
    }

    /* From column 2 it spans 2 rows */
    .top-nav {
        display: block;
        grid-column: 2 / span 2;
        margin-top: 1.4rem;
    }

    .hero-center {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;
        z-index: 0;
    }

    .hero-img-wrapper {
        min-width: 240px;
        width: 240px;
        height: 250px;
        background-color: var(--white);
        border-radius: 10px;
        position: relative;
    }

    .hero-left {
        display: block;
        padding-left: 2rem;
    }

    .main-info button {
        min-width: 180px;
        padding: 10px 0;
        font-size: 16px;
        color: var(--white);
    }
}

