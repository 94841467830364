@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;700&display=swap');

* {
  font-family: 'Karla', sans-serif;
  margin: 0;
  padding: 0;
  --red: #D8315B;
  --black: #040508;
  --blue: #253039;
  --white: #F2F5EA;
  --grey: #dadada;
  --blueBar: #328DC2;
  --greenBar: #52B1AB;
}

body {
  background-color: var(--white);
  color: var(--white);
}

h1 {
  color: var(--white);
  font-size: 48px;
}

h2 {
  font-size: 32px;
  font-weight: 600;
}

h3 {
  font-size: 22px;
  font-weight: 300;
}

a {
  text-decoration: none;
}

/* Helper Classes */
.btn-cta {
  background: var(--red);
  font-weight: 600;
  border: none;
  color: var(--white);
}

.btn-cta:hover {
  background: #981b3a;
  transition: background 0.2s;
}

ul li:hover {
  color: var(--red);
}

.btn-sec {
  border: 1px solid var(--white);
  background: none;
  color: var(--white);
  text-decoration: none;
  height: 38px;
}  

.btn-sec:hover {
  background: rgba(117, 117, 135, 0.4);
  transition: background 0.2s;
}  

/* Center Block element with a width */
.-c{
  margin: 0 auto;
}

button:hover,li {
  cursor: pointer;
}




