.project-container_ {
    background-color: var(--blue);
    min-width: 10px;
    width: 260px;
    height: 353px;
    margin: 10px 20px;
    border: 1px solid white;
}

.info-wrapper {
    padding: 1rem;
}

.project-img_ {
    width: 100%;
    height: 150px;
}

.project-img_ img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-container_ h3{
    color: white;
}

.project-container_ p {
    margin: 8px 0px 20px 0px;
    color: white;
    height: 58px;
    overflow: hidden;
}


.project-container_ button {
    display: block;
    margin: 0 auto;
    width: 120px;
    line-height: 34px;
}


@media only screen and (max-width: 600px){

}